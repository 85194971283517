import Rollbar from 'rollbar'
const rollbarToken = document.head.querySelector("meta[name='rollbar']").content
const railsEnv = document.head.querySelector("meta[name='environment']").content
const gitRevision = document.head.querySelector("meta[name='revision']").content
const enabled = ['staging', 'production'].includes(railsEnv) && !!rollbarToken
const scrubFields = document.head
  .querySelector("meta[name='scrub-fields']")
  .content.split(',')
  .map((field) => field.trim())

const accountId = document.head.querySelector("meta[name='assignr:account']")
  ? document.head.querySelector("meta[name='assignr:account']").content
  : null
const accountName = document.head.querySelector("meta[name='assignr:name']")
  ? document.head.querySelector("meta[name='assignr:name']").content
  : null
const siteId = document.head.querySelector("meta[name='assignr:site']")
  ? document.head.querySelector("meta[name='assignr:site']").content
  : null
const siteName = document.head.querySelector("meta[name='assignr:sitename']")
  ? document.head.querySelector("meta[name='assignr:sitename']").content
  : null

const telemetryScrubber = (theNode) => {
  var sensitiveRegex = /(number|ssn|birth|ein|tin|passport)/gi
  var returnValue = false
  if (theNode.tagName == 'input') {
    // Stripe fields seem to have a Card class
    if (theNode.classes) {
      for (var i = 0; i < theNode.classes.length; i++) {
        if (theNode.classes[i].match(/^Card/)) {
          returnValue = true
        }
      }
    }
    for (var i = 0; i < theNode.attributes.length; i++) {
      if (theNode.attributes[i].key == 'type') {
        // password fields are always rejected
        if (theNode.attributes[i].value == 'password') {
          returnValue = true
        }
        if (['number', 'text'].indexOf(theNode.attributes[i].value) >= 0) {
          // number and text fields with ID matching sensitive pattern are rejected
          if (theNode.id && theNode.id.match(sensitiveRegex)) {
            returnValue = true
          }
        }
      }
      if (theNode.attributes[i].key == 'name') {
        // fields with a name matching sensitive pattern are rejected
        if (theNode.attributes[i].value.match(sensitiveRegex)) {
          returnValue = true
        }
      }
    }
  }
  return returnValue
}

let config = {
  accessToken: rollbarToken,
  captureUncaught: true,
  enabled: enabled,
  captureUnhandledRejections: false,
  payload: {
    environment: railsEnv,
    revision: gitRevision
  },
  scrubFields: scrubFields, 
  telemetryScrubber: telemetryScrubber, 
}
if (accountId) {
  config.payload.person = {
    id: accountId,
    username: accountName
  }
}
if (siteId) {
  config.payload.site = {
    id: siteId,
    name: siteName
  }
}

if (rollbarToken) {
  Rollbar.init(config)
}
