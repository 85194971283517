import { ApplicationController } from "./application_controller.js"
import SignaturePad  from 'signature_pad'
export default class extends ApplicationController {
  static targets = [ 'canvas' ]
  static values = {
    width: Number
  }
  connect() {
    const background = new Image(this.widthValue,this.widthValue);
    const canvas = this.canvasTarget 
    const context = canvas.getContext("2d");
    background.src = "https://assignr-prod.s3.amazonaws.com/assets/form_templates/ussf_supplemental/blank-soccer-field-square.jpg";

    // Make sure the image is loaded first otherwise nothing will draw.
    background.onload = () =>{
        context.drawImage(background,0,0);   
    }
    console.log('hello field diagram', this.element)
    const signaturePad = new SignaturePad(this.canvasTarget, {penColor: 'crimson'})
  } // connect()
}
