import TomSelect from "tom-select";
import { ApplicationController } from "./application_controller";
import { api } from "../components/AxiosHeaders";
import { queryString } from "../components/QueryString";

export default class extends ApplicationController {
    connect() {
        this.build();
    }

    build() {
        this.element.classList.remove("form-control");
        this.tomselect = new TomSelect(this.element, {
            valueField: 'id',
            labelField: 'text',
            searchField: ['text'],
            maxOptions: null,
            loadThrottle: 500,
            sortField: [{ field: '$order' }, { field: '$score' }],
            plugins: ['clear_button', 'virtual_scroll', 'remove_button'],
            preload: true,
            firstUrl: function (query) {
                const qs = queryString({
                    page: 1,
                    per_page: 1000,
                    q: query
                });
                return `/autocomplete/teams.json?${qs}`;
            },
            load: function (query, callback) {
                const url = this.getUrl(query) || this.firstUrl(query);
                api.get(url)
                    .then((response) => {
                        const { pagination: { more, next_page }, results } = response.data;
                        if (more) {
                            const qs = queryString({
                                page: next_page,
                                per_page: 1000,
                                q: query
                            });
                            this.setNextUrl(query, `/autocomplete/teams.json?${qs}`);
                        }
                        callback(results);
                    }
                    )
                    .catch((error) => {
                        console.log(error);
                        callback();
                    });
            }
        });
    }
}
