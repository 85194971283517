import { ApplicationController } from './application_controller.js'
import { Calendar } from '@fullcalendar/core'
import Cookies from 'js-cookie'
import dayGridPlugin from '@fullcalendar/daygrid'
import resourceTimelinePlugin from '@fullcalendar/resource-timeline'
import momentPlugin from '@fullcalendar/moment'
import momentTimezonePlugin from '@fullcalendar/moment-timezone'
import interactionPlugin from '@fullcalendar/interaction'
import timeGridPlugin from '@fullcalendar/timegrid'
import listPlugin from '@fullcalendar/list'
import moment from 'moment-timezone'
import tippy from 'tippy.js'
import { api } from '../components/AxiosHeaders.js'
import $ from 'jquery'
import { h } from 'preact'

export default class extends ApplicationController {
  static targets = ['calendar', 'modal', 'timeZoneButton', 'timeZoneSelect']
  static values = {
    // minTime: String,
    // maxTime: String,
    availableViews: Array,
    defaultView: String,
    assignor: Boolean,
    scrollTime: String,
    headerButtons: String,
    zone: String,
    firstDay: Number,
    subvenue: Boolean,
    timeZones: Object,
    initialTimeZone: String
  }

  get multiZone() {
    return Object.keys(this.timeZonesValue).length > 1
  }

  timeZoneButtonTargetConnected(target) {
    console.log('timeZoneButtonTargetConnected')
    if (this.timeZone) {
      target.innerHTML = this.lookupTimeZoneAbbrev(this.timeZone)
    }
  }

  changeTimeZone(event) {
    console.log('change!', this.timeZoneSelectTarget.value)
    Cookies.set('fullcalendar_tz', this.timeZoneSelectTarget.value)
    this.calendar.setOption('timeZone', this.timeZoneSelectTarget.value)
    this.timeZoneButtonTargets.forEach(
      (target) =>
        (target.innerHTML = this.lookupTimeZoneAbbrev(
          this.timeZoneSelectTarget.value
        ))
    )
  }

  connect() {
    this.timeZone = this.initialTimeZoneValue
    console.log(this.timeZonesValue, 'tz')
    console.log(this.multiZone, 'multi')
    if (Cookies.get('main-calendar-now')) {
      var initialDate = moment(Cookies.get('main-calendar-now')).toDate()
    } else {
      var initialDate = moment().toDate()
    }
    if (this.availableViewsValue.includes(Cookies.get('main-calendar-view'))) {
      var defaultView = Cookies.get('main-calendar-view')
    } else {
      var defaultView = this.defaultViewValue
    }

    var eventSources = [{ url: '/calendars/games.json' }]
    if (this.assignorValue) {
      eventSources.push({
        url: '/calendars/games.json',
        extraParams: { dates: 1 }
      })
    }
    const controller = this
    const eventContentWithVenue = ({ event, view }) => {
      // this is the preferred way of adding timezone to the event
      // description
      const {
        tz: eventTimeZone,
        eventType,
        titleWithVenue,
        start
      } = event.extendedProps
      const { timeZone: calendarTimeZone } = view.dateEnv
      let anchor = document.createElement('a')
      anchor.href = event.url
      console.log('eventContentWithVenue', view.type, event.url)
      if (eventType == 'game') {
        let newTitle
        const tzMatch =
          eventTimeZone && calendarTimeZone
            ? moment.tz(eventTimeZone).format('z') ==
              moment.tz(calendarTimeZone).format('z')
            : true

        if (!tzMatch) {
          newTitle = `(${start}) ${titleWithVenue}`
        } else {
          newTitle = titleWithVenue
        }
        if (['listWeek', 'listFourWeek'].includes(view.type)) {
          // internals of fullcalendar are looking for an a[href] tag
          // to intercept the click of a list item
          // which doesn't seem to be present on list views,
          // so we are making one here
          anchor.innerHTML = newTitle
          return { domNodes: [anchor] }
        } else {
          return newTitle
        }
      }
      if (eventType == 'date') {
        if (['listWeek', 'listFourWeek'].includes(view.type)) {
          anchor.innerHTML = event.title
          return { domNodes: [anchor] }
        } else {
          return event.title
        }
      }
      console.log(`fallback: ${event.title}`)
      return event.title
    }
    const eventContentWithoutVenue = ({ event, view }) => {
      // this is the preferred way of adding timezone to the event
      // description
      console.log('eventContentWithoutVenue', view.type)
      const {
        tz: eventTimeZone,
        eventType,
        titleWithoutVenue,
        start
      } = event.extendedProps
      const { timeZone: calendarTimeZone } = view.dateEnv

      const tzMatch =
        eventTimeZone && calendarTimeZone
          ? moment.tz(eventTimeZone).format('z') ==
            moment.tz(calendarTimeZone).format('z')
          : true

      if (eventType == 'game') {
        if (tzMatch) {
          return titleWithoutVenue
        } else {
          return `(${start}) ${titleWithoutVenue}`
        }
      }
      return event.title
    }

    var calOptions = {
      aspectRatio: 1.75,
      customButtons: {
        timeZone: {
          text: '',
          click: (event, element) => console.log('clicky')
        }
      },
      schedulerLicenseKey: '0151539399-fcs-1676166803',
      plugins: [
        dayGridPlugin,
        interactionPlugin,
        listPlugin,
        momentPlugin,
        momentTimezonePlugin,
        resourceTimelinePlugin,
        timeGridPlugin
      ],
      initialView: defaultView,
      initialDate: initialDate,
      scrollTime: this.scrollTimeValue,
      firstDay: this.firstDayValue,
      slotMinWidth: 50,
      headerToolbar: {
        left: 'title',
        center: '',
        right: `today prev,next ${this.headerButtonsValue}${
          this.multiZone ? ' timeZone' : ''
        }`
      },
      views: {
        listFourWeek: {
          type: 'list',
          duration: { days: 28 },
          buttonText: 'list',
          listDayFormat: `dddd`,
          listDaySideFormat: `MMMM D, YYYY`,
          listDayAltFormat: null,
          allDayText: '',
          noEventsMessage: 'No games found for this time range.',
          eventContent: eventContentWithVenue
        },
        listWeek: {
          listDayFormat: `dddd, MMMM D`,
          // listDaySideFormat: ' ',
          listDayAltFormat: null,
          allDayText: '',
          noEventsMessage: 'No games found for this time range.',
          eventContent: eventContentWithVenue,
          eventClick: ({ event }) => {
            console.log('click!', event)
          },
          // eventTimeFormat: (args)=>{
          //   // this doesn't work because the timeZone provided is the calendar's time zone,
          //   // NOT the event time zone
          //   const {timeZone, start} = args
          //   console.log('etf',args)
          //   const {array: startTimeArray} = start
          //   const abbreviatedTz = timeZone && moment.tz(timeZone).format('z')
          //   window.moment = moment
          //   window.etfDate = startTimeArray
          //   if (controller.multiZone){
          //     return `${moment(startTimeArray).format('h:mm a')} ${abbreviatedTz}`
          //   } else {
          //     return `${moment(startTimeArray).format('h:mm a')}`
          //   }
          // },
          displayEventEnd: false
        },
        timeGridWeek: {
          scrollTime: '08:00:00',
          // minTime: this.minTimeValue,
          // maxTime: this.maxTimeValue,
          allDaySlot: this.assignorValue,
          allDayText: '',
          slotDuration: '00:15:00',
          slotLabelInterval: '01:00',
          nowIndicator: true,
          eventDidMount: ({ el, event, view }) => {
            console.log('edm1', view.type)
            const timeZone = view.calendar.getOption('timeZone')
            const {
              titleWithVenue,
              start,
              startTimeShort,
              eventType,
              tz: eventTimeZone
            } = event.extendedProps
            if (eventType == 'date') {
              event.setProp('textColor', 'black')
            }
            if (['game', 'assignment'].includes(eventType)) {
              tippy(el, {
                content: `${start} @ ${titleWithVenue}`
              })
              // for some reason, if we attempt to alter .fc-event-time,
              // the calendar "local" time range is appended after the text content
              // so we just need to append the local time to the start of the event title
              // since that's the only option that appears to be working.
              const titleElement = el.querySelector('.fc-event-title')
              const timeElement = el.querySelector('.fc-event-time')
              if (titleElement && ['game', 'assignment'].includes(eventType)) {
                const tzMatch =
                  eventTimeZone && timeZone
                    ? moment.tz(eventTimeZone).format('z') ==
                      moment.tz(timeZone).format('z')
                    : true

                const abbreviatedTz =
                  eventTimeZone && moment.tz(eventTimeZone).format('z')
                if (!tzMatch) {
                  titleElement.innerHTML = `(${startTimeShort} ${abbreviatedTz})&nbsp;`
                } else {
                  // titleElement.innerHTML = `${startTimeShort}`
                }
              }
            }
          }
        },
        dayGridMonth: {
          eventLimit: true, // adjust to 6 only for agendaWeek/agendaDay
          fixedWeekCount: false, // true: show 6 weeks; false: show 4,5 or 6
          // eventContent not working well here...
          // eventContent: eventContentWithVenue,
          eventDidMount: ({ event, view, el: element }) => {
            const { extendedProps } = event
            const timeZone = view.calendar.getOption('timeZone')
            const {
              titleWithVenue,
              start,
              startTimeShort,
              eventType,
              tz: eventTimeZone
            } = extendedProps
            if (eventType == 'date') {
              event.setProp('textColor', 'black')
            }
            if (['game', 'assignment'].includes(eventType)) {
              tippy(element, {
                content: `${start} @ ${titleWithVenue}`
              })
              var titleElement = element.querySelector('.fc-event-title')
              const abbreviatedTz =
                eventTimeZone && moment.tz(eventTimeZone).format('z')

              const tzMatch =
                eventTimeZone && timeZone
                  ? moment.tz(eventTimeZone).format('z') ==
                    moment.tz(timeZone).format('z')
                  : true

              if (titleElement) {
                if (tzMatch) {
                  // timeElement.innerHTML = `${startTimeShort}`
                } else {
                  titleElement.innerHTML = `(${startTimeShort} ${abbreviatedTz}) @ ${titleWithVenue}&nbsp;`
                }
              }
            }
          }
        },
        resourceTimelineDay: {
          // resourceGroupField: 'zone',
          // minTime: this.minTimeValue,
          // maxTime: this.maxTimeValue,
          titleFormat: `dddd, MMMM D, YYYY`,
          eventContent: eventContentWithoutVenue,
          eventDidMount: ({ event, view, el: element }) => {
            console.log('edm3')
            const { extendedProps } = event
            // const timeZone = view.calendar.getOption('timeZone')
            const {
              titleWithVenue,
              start,
              eventType,
              tz: eventTimeZone
            } = extendedProps
            if (['game', 'assignment'].includes(eventType)) {
              tippy(element, {
                content: `${start} @ ${titleWithVenue}`
              })
            }
          }
        }
      },
      navLinks: true,
      navLinkDayClick: function (date, jsEvent) {
        console.log('navLinkDayClick', date)
        console.log(
          'calling changeView with',
          moment(date).format('YYYY-MM-DD')
        )
        controller.calendar.changeView(
          'resourceTimelineDay',
          moment(date).format('YYYY-MM-DD')
        )
      },

      datesSet: ({ view, timeZone }) => {
        const { currentStart } = view
        console.log('datesSet', currentStart, timeZone, view.type)
        const date = currentStart.toUTCString()
        Cookies.set('main-calendar-view', view.type)
        Cookies.set('main-calendar-now', date)
        this.currentView = view
      },
      timeZone: this.initialTimeZoneValue,
      eventSources: eventSources,
      // as long as the id is the game's sort key, this will work correctly
      resourceOrder: 'id,title',
      resources: function (fetchInfo, successCallback, failureCallback) {
        api
          .get('/calendars/venues.json', {
            params: {
              start: fetchInfo.startStr,
              end: fetchInfo.endStr
            }
          })
          .then((response) => {
            successCallback(response.data)
            window.dispatchEvent(new CustomEvent('resize'))
          })
          .catch((error) => failureCallback())
      },
      refetchResourcesOnNavigate: true,
      resourceAreaHeaderContent: 'Location',
      dayMaxEventRows: true,
      dayMaxEvents: true,
      // For timeline view, the maximum number of events that stack top-to-bottom.
      // For timeGrid view, the maximum number of events that stack left-to-right.
      eventMaxStack: 3
    }

    // if (this.minTimeValue) {
    //   calOptions.views.timeGridWeek.slotMinTime = this.minTimeValue
    //   calOptions.views.resourceTimelineDay.slotMinTime = this.minTimeValue
    // }
    // if (this.maxTimeValue) {
    //   calOptions.views.timeGridWeek.slotMaxTime = this.maxTimeValue
    //   calOptions.views.resourceTimelineDay.slotMaxTime = this.maxTimeValue
    // }
    /*
      zone only: just group resources by zone 
      subvenue only: area columns are venue/subvenue 
      zone and subvenue: area columns are zone/venue/subvenue 
      neither: 
    */
    if (this.zoneValue && !this.subvenueValue) {
      // zone only: just group resources by zone
      calOptions.views.resourceTimelineDay.resourceGroupField = 'zone'
    } else if (this.zoneValue && this.subvenueValue) {
      // zone and subvenue: area columns are zone/venue/subvenue
      calOptions.views.resourceTimelineDay.resourceAreaColumns = [
        {
          group: true,
          field: 'zone',
          headerContent: this.zoneValue,
          width: '25%'
        },
        {
          field: 'title',
          headerContent: 'Venue',
          width: '50%',
          group: true
        },
        {
          field: 'subvenue',
          headerContent: 'Sub-Venue',
          width: '25%'
        }
      ]
    } else if (!this.zoneValue && this.subvenueValue) {
      // subvenue only: area columns are venue/subvenue
      calOptions.views.resourceTimelineDay.resourceAreaColumns = [
        {
          field: 'title',
          headerContent: 'Venue',
          width: '70%',
          group: true
        },
        {
          field: 'subvenue',
          headerContent: 'Sub-Venue',
          width: '30%'
        }
      ]
    }

    // if (this.zoneValue && this.subvenueValue) {
    //   // available in fullcalendar >= 5.5.1
    //   calOptions.views.resourceTimelineDay.resourceAreaColumns = [
    //     {
    //       group: true,
    //       field: 'zone',
    //       headerContent: this.zoneValue,
    //       width: '40%'
    //     },
    //     {
    //       field: 'title',
    //       headerContent: 'Venue',
    //       width: '40%'
    //     },
    //     {
    //       field: 'subvenue',
    //       headerContent: 'Sub-Venue',
    //       width: '20%'
    //     }
    //   ]
    // }

    console.log('init calendar with', calOptions)
    this.calendar = new Calendar(this.calendarTarget, calOptions)
    this.calendar.render()
    const tzButton = document.getElementsByClassName('fc-timeZone-button')[0]
    if (tzButton) {
      tzButton.dataset.toggle = 'modal'
      tzButton.dataset.target = '#timeZoneModal'
      tzButton.dataset.calendarMainTarget = 'timeZoneButton'
    }
    // find .fc-timeZone-button and add a target
    window.dispatchEvent(new CustomEvent('resize'))
  }

  lookupTimeZoneName(value) {
    return this.timeZonesValue[value]
  }

  lookupTimeZoneAbbrev(value) {
    console.log(`lookupTimeZoneAbbrev: ${value}`)
    return moment().tz(value).format('z')
  }
}
