/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

// initiates rollbar error reporting
import './rollbar.js'
import 'core-js/stable'
import 'polyfill-object.fromentries'
import 'regenerator-runtime/runtime'
import { Application } from '@hotwired/stimulus'
import { definitionsFromContext } from '@hotwired/stimulus-webpack-helpers'
import * as ActiveStorage from '@rails/activestorage'
import Dinero from 'dinero.js'
import Rails from '@rails/ujs'
import 'trix'
import '@rails/actiontext'
// import { $, jQuery } from 'jquery'

// make it available to legacy jquery code
window.Dinero = Dinero

// window.$ = $
// window.jQuery = jQuery
const application = Application.start()
const context = require.context('./controllers', true, /\.js$/)
application.load(definitionsFromContext(context))
// require("./components/GameForm");
Rails.start()
ActiveStorage.start()

import './css/pikaday.scss'
import './css/alpinejs.scss'
import './css/trix.scss'
import './css/fontawesome.scss'
import './legacy/index.js'
import 'trix/dist/trix.css'
import 'tabulator-tables/dist/css/tabulator.css'
import 'tippy.js/dist/tippy.css';
import "formiojs/dist/formio.full.css"
import "./enterprise_site";
