import { ApplicationController } from './application_controller.js'
import { api } from '../components/AxiosHeaders'
import { sum, inputToInteger, intToCurrency } from '../components/Financials'
import { htmlToElement } from '../components/Html'
export default class extends ApplicationController {
  static targets = [
    'approved',
    'approvedAssignor',
    'assignment',
    'assignor',
    'defaultFee',
    'expense',
    'fee',
    'feeInput',
    'lastName',
    'officialSelect',
    'payorSelect',
    'payorLabel',
    'total'
  ]

  static values = {
    payable: Boolean,
    game: Number,
    payor: String
  }
  connect() {
    if (this.payorValue == 'none' || !this.payorValue) {
      this.allApprovalCheckboxes.forEach((element) => {
        element.checked = false
        element.disabled = true
      })
    }
    this.populate()
  }

  get allApprovalCheckboxes() {
    return this.approvedTargets.concat(this.approvedAssignorTargets)
  }

  feeInputTargetConnected(target) {
    // prevent up/down from changing override fee
    target.addEventListener('keydown', function (e) {
      // prevent up/down keys from changing override values
      if (e.which === 38 || e.which === 40) {
        e.preventDefault()
      }
    })
  }
  // document.getElements

  // const highlightedItems = document.querySelectorAll("input[type=number]");

  // highlightedItems.forEach((userItem) => {
  //   deleteUser(userItem);
  // });

  get gameApproved() {
    const checkboxes = this.approvedTargets.concat(this.approvedAssignorTargets)
    return checkboxes.every(
      (checkbox) => checkbox.checked && !checkbox.disabled
    )
  }

  get assignmentCount() {
    return this.approvedTargets.filter((element) => !element.disabled).length
  }

  get approvedAssignmentCount() {
    return this.approvedTargets.filter(
      (element) => element.checked && !element.disabled
    ).length
  }

  gameTotal() {
    return this.gameFeeTotal() + this.assignorAmount() + this.expensesAmount()
  }

  approvedGameTotal() {
    return (
      this.approvedGameFeeTotal() +
      this.approvedAssignorAmount() +
      this.approvedExpensesAmount()
    )
  }

  gameFeeTotal() {
    if (this.payableValue) {
      return this.feeInputTargets
        .map((element) => element.value || element.placeholder)
        .map((value) => inputToInteger(value))
        .reduce(sum, 0)
    } else {
      return 0
    }
  }

  approvedGameFeeTotal() {
    if (this.payableValue) {
      return this.feeInputTargets
        .filter((element) =>
          this.approvedAssignmentIDs.includes(element.dataset.assignment)
        )
        .map((element) => element.value || element.placeholder)
        .map((value) => inputToInteger(value))
        .reduce(sum, 0.0)
    } else {
      return 0
    }
  }

  approvedAssignorAmount() {
    try {
      return this.approvedAssignorTargets
        .filter((element) => element.checked)
        .map((element) => {
          const parentAssignor = element.closest('[data-fee]');
          return Number(parentAssignor ? parentAssignor.dataset.fee : 0.0);
        })
        .reduce(sum, 0.0);
    } catch (error) {
      console.log('aaa error', error);
      return 0;
    }
    // try {
    //   if (this.approvedAssignorTarget.checked) {
    //     return Number(this.assignorTarget.dataset.fee || 0.0)
    //   } else {
    //     return 0
    //   }
    // } catch (error) {
    //   console.log('aaa error', error)
    //   return 0
    // }
  }

  assignorAmount() {
    try {
      return this.assignorTargets
        .map((element) => element.dataset.fee)
        .reduce(sum, 0.0)
    } catch (error) {
      console.log('bbb error', error);
      return 0;
    }
    // if (this.hasAssignorTarget) {
    //   return Number(this.assignorTarget.dataset.fee || 0.0)
    // } else {
    //   return 0
    // }
  }

  expensesAmount() {
    return this.expenseTargets
      .map((element) => element.dataset.fee)
      .reduce(sum, 0.0)
  }

  approvedExpensesAmount() {
    return this.expenseTargets
      .filter((element) =>
        this.approvedAssignmentIDs.includes(element.dataset.assignment)
      )
      .map((element) => element.dataset.fee)
      .reduce(sum, 0.0)
  }

  get approvedAssignmentIDs() {
    return this.approvedTargets
      .filter((target) => target.checked)
      .map((target) => target.dataset.assignment)
  }

  // this is called to populate relevant value totals on the game
  populate(event) {
    this.element.dataset.gameTotal = this.gameTotal()
    this.element.dataset.approvedTotal = this.approvedGameTotal()
    this.totalTargets.forEach((target) => {
      target.innerHTML = intToCurrency(this.approvedGameTotal())
    })
  }

  // this is called when a value on a game changes
  async recalculate(event) {
    this.populate(event)
    const recalcEvent = new CustomEvent('approve:recalculate', {
      detail: {
        game: this.gameValue,
        payor: this.payorValue
      }
    })
    this.element.dispatchEvent(recalcEvent)
    console.log('recalculate', event)
    console.log([
      this.gameFeeTotal(),
      this.assignorAmount(),
      this.expensesAmount()
    ])
    console.log([
      this.approvedGameFeeTotal(),
      this.assignorAmount(),
      this.approvedExpensesAmount()
    ])
    console.log(`new fee total is ${intToCurrency(this.gameTotal())}`)
    console.log(
      `assignment counts: ${this.assignmentCount} ~ ${this.approvedAssignmentCount}`
    )
    console.log(
      `new approved fee total is ${intToCurrency(this.approvedGameTotal())}`
    )
  }

  approveAll(event) {
    event.preventDefault()
    const checkboxes = this.approvedTargets.concat(this.approvedAssignorTargets)
    if (checkboxes.every((target) => target.checked)) {
      checkboxes.forEach((target) => (target.checked = false))
    } else {
      checkboxes.forEach((target) => (target.checked = true))
    }
    this.recalculate()
  }

  // called when name of official is clicked
  // get select element remotely & insert into DOM
  reassign(event) {
    event.preventDefault()
    if (this.reassigning) {
      return true
    }
    const { target } = event
    const { href } = target
    const controller = this
    this.reassigning = true
    api
      .get(href, { headers: { Accept: 'text/html' } })
      .then((resp) => {
        const html = resp.data
        target.after(htmlToElement(html))
        controller.hideElement(target)
      })
      .catch((error) => {
        controller.reassigning = false
        console.log('oops', error)
      })
  }

  // called when official is changed
  officialChanged(event) {
    event.preventDefault()
    const { assignment } = event.target.dataset
    const official = event.target.selectedOptions[0]
    const lastName = official.dataset.lastName
    console.log('officialChanged', official)
    this.expenseTargets
      .filter((expenseTarget) => expenseTarget.dataset.assignment == assignment)
      .forEach((expenseTarget) => {
        this.lastNameTargets
          .filter((target) => expenseTarget.contains(target))
          .forEach((nameTarget) => {
            nameTarget.innerHTML = lastName
          })
      })
  }

  showPayor(event) {
    if (event) {
      event.preventDefault()
    }
    this.payorLabelTargets.forEach((target) => this.hideElement(target))
    this.payorSelectTargets.forEach((target) => {
      target.disabled = false
      this.showElement(target)
    })
  }

  payorChanged() {
    if (this.hasPayorSelectTarget) {
      const payorSelected = !!this.payorSelectTarget.value
      const checkboxes = this.approvedTargets.concat(
        this.approvedAssignorTargets
      )
      checkboxes.forEach((element) => {
        if (!payorSelected) {
          element.checked = false
        }
        element.disabled = !payorSelected
      })
      this.element.dataset.approveGamePayorValue =
        this.payorSelectTarget.value || 'none'
      this.recalculate()
    }
  }

  showFeeInput(event) {
    const { target } = event
    event.preventDefault()
    const controller = this
    this.assignmentTargets.forEach((assignmentTarget) => {
      this.feeInputTargets.forEach((feeInputTarget) => {
        if (
          assignmentTarget.contains(feeInputTarget) &&
          assignmentTarget.contains(target)
        ) {
          controller.hideElement(target)
          controller.showElement(feeInputTarget)
          feeInputTarget.disabled = false
        }
      })
    })
  }
}
